<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-03 14:14:04
 * @LastEditTime: 2021-06-22 16:34:51
 * @LastEditors: Please set LastEditors
 * @Description: 退回弹框
 * @FilePath: \user\src\views\home\myEn\components\readComm\viewReject.vue
-->
<template>
  <div>
    <en-dialog
      :visible="visiable"
      title="退回"
      :close-on-click-modal="false"
      @close="closeDialog"
      style="text-aglin:left;"
      :append-to-body="true"
    >
      <div class="box">
        <div
          class="col-mod"
          v-if="options.length > 0"
        >
          <span>选择退回</span>
          <en-select
            v-if="options.length > 0"
            v-model="select"
            placeholder="请选择"
            :data="options"
            filterable
            style="display: inline-block;margin-left:15px;"
            @change="change"
          ></en-select>
        </div>
        <textarea v-model="approvalComment" class="text" name="" cols="30" rows="10" placeholder="请输入退回意见" maxlength="500"></textarea>
      </div>
      <div slot="footer">
        <en-button @click="saveClick">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import { enService } from "@/api/en/index";

export default {
  name: "ViewReject",
  props: {
    // 整个数据体
    content: {
      type: Object,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "reject"
    }
  },
  data() {
    return {
      visiable: false,
      options: [],
      select: "",
      approvalComment: "",
      // 函数节流 1未使用 2使用中
      throttle: 1,
      // 是否刷新
      flgRefresh: false
    };
  },
  mounted() {
    this.visiable = this.show;
    if (this.type === "reject") {
      this.queryFlowNodeInfo();
    }
  },
  methods: {
    // 获取流程节点
    async queryFlowNodeInfo() {
      const p = {
        businessKey: this.content.businessKey,
        pId: this.content.pId,
        backAttr: this.content.backAttr
      };
      const rspData = await enService.queryFlowNodeInfo(p) || [];
      this.options = rspData;
    },
    // 确定按钮
    saveClick() {
      if (this.type === "reject") {
        this.mdReject();
      } else {
        this.rebut();
      }
    },
    // 退回
    async mdReject() {
      if (this.throttle === 2) {
        return;
      }
      // 选择节点的
      this.throttle = 2;
      if (this.approvalComment === "") {
        this.$message({
          message: "请输入退回意见",
          type: "waring"
        });
        this.throttle = 1;
        return;
      }
      const p = {
        tenantId: this.content.tenantId,
        id: this.content.id,
        pId: this.content.pId,
        approvalComment: this.approvalComment
      };
      try {
        await enService.mdReject(p);
        this.$message({
          message: "退回成功",
          type: "success"
        });
        this.flgRefresh = true;
        this.throttle = 1;
        this.visiable = false;
      } catch (error) {
        this.throttle = 1;
      }
    },
    // 驳回
    async rebut() {
      if (this.throttle === 2) {
        return;
      }
      this.throttle = 2;
      if (this.approvalComment === "") {
        this.$message({
          message: "请输入驳回意见",
          type: "waring"
        });
        this.throttle = 1;
        return;
      }
      const p = {
        tenantId: this.content.tenantId,
        id: this.content.id,
        pId: this.content.pId,
        customNodeId: "start",
        customUserId: "",
        businessType: this.content.businessType,
        approvalComment: this.approvalComment
      };
      try {
        await enService.mdReject(p);
        this.$message({
          message: "驳回成功",
          type: "success"
        });
        this.flgRefresh = true;
        this.throttle = 1;
        this.visiable = false;
      } catch (error) {
        this.throttle = 1;
      }
    },
    // 关闭回调
    closeDialog() {
      const data = {
        itemName: "reject",
        flgRefresh: this.flgRefresh
      };
      this.$emit("toShow", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.box{
  margin: 0 auto;
  width:800px;
  min-height:115px;
  font-size:12px;
  line-height: 14px;
  font-family:Microsoft YaHei;
  font-weight:400;
  color:rgba(99,108,120,1);
  .col-mod{
    margin-bottom: 10px;
  }
  .text{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 5px 12px;
    border: 1px solid #e5e6e7;
    resize: none;
  }
}
</style>
