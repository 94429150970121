<template>
  <!--待办事项-->
  <div class="my-enlis" @click.stop="hideSearch">
    <div class="ybox-mod enbuss-mod">
      <div class="ybox-title">
        <card-header :title="headerTitle" :num="num" :edit="$attrs.edit" @deleteData="deleteData" :menuData='menuData'  @editTheme='editTheme' >
          <template #right>
            <div class="pull-right">
              <viewSearch v-if="type === 'itself' && !$attrs.edit" :show="show" @toData="getData"></viewSearch>
              <span v-if="type === 'itself' && !$attrs.edit" class="shu"></span>
              <!-- 排序按钮 -->
              <en-image-setting
                v-if="type === 'itself' && sortType === '001' && sortLoad && !$attrs.edit"
                class="piliangchuli-weidu sotrel"
                name="jiangxu"
                size="small"
                fill="rgb(169, 181, 198)"
                @click.native="sortClick('000', 'taskToDoSortType')"
              >
                降序
              </en-image-setting>
              <en-image-setting
                v-if="type === 'itself' && sortType === '000' && sortLoad && !$attrs.edit"
                class="piliangchuli-weidu sotrel"
                name="shengxu1"
                size="small"
                fill="rgb(169, 181, 198)"
                @click.native="sortClick('001', 'taskToDoSortType')"
              >
                升序
              </en-image-setting>
              <en-image-setting v-if="mainBatchOpera && !$attrs.edit" class="piliangchuli-weidu" name="piliangchuli" size="small" fill="#4593DF" @click.native="messageBatch()">
                批量操作
              </en-image-setting>
              <en-icon
                v-if="type === 'index' && !$attrs.edit"
                name="zuocecaidanlan-shouqi"
                size="20"
                style="color: #A9B5C6;cursor: pointer;margin-left: 10px;"
                @click.native="goDetails('/waitdo')"
              ></en-icon>
            </div>
          </template>
        </card-header>
      </div>
      <div class="ybox-content" v-en-loading="loading">
        <div class="enlis enlis-buss" v-if="!noData" id="waitdo-enlis" ref="enlisbox">
          <div v-for="(item, k) in noticeList" :key="k" class="mod-box">
            <div class="mod" @click.stop="modClick(item, k)">
              <div class="col col-icon" :class="item.readFlag === '000' ? 'msg-remind' : ''">
                <p>
                  <en-user-logo style="width:40px;height:40px;" :user-name="item.createName" :image-url="item.userLogo" size="40"></en-user-logo>
                </p>
              </div>
              <div class="pull-left">
                <div class="col col-num">
                  <p>
                    {{ item.createName || "" }}
                    <!-- {{ (item.barCode || "") + " " + (item.createName || "") }} -->
                    <!-- 共享人标识 -->
                    <en-icon v-if="item.assignee !== userId" name="dailishenpiren" size="16" style="color:#3e90fe;"></en-icon>
                    <!-- 超时提醒闹铃 -->
                    <en-icon
                      v-if="item.approvalLimitTime && (item.surplusTime || 0) < 4320"
                      name="gongshi"
                      size="16"
                      :style="Number(item.surplusTime || 0) > 0 ? 'color:#12b7f5;' : 'color:#f54646;'"
                    ></en-icon>
                    <span v-if="item.approvalLimitTime && (item.surplusTime || 0) < 4320" :style="Number(item.surplusTime || 0) > 0 ? 'color:#12b7f5;' : 'color:#f54646;'">{{ expire(item) }}</span>
                  </p>
                </div>
                <div class="col col-tit">
                  <p>{{ item["message"] || "" }}</p>
                </div>
              </div>
              <div class="pull-right">
                <div class="col col-time">
                  <p>{{ getDateFormat(item["createTime"]) }}</p>
                </div>
                <div class="col col-operate" style="display: flex;justify-content: flex-end;">
                  <span class="span-btn" title="同意" v-if="btnAgree(item)">
                    <en-icon name="tongyi-kuaijiecaozuo" size="20" style="color:#3e90fe;" @click.native.stop.once="mdAgree(item, k)"></en-icon>
                  </span>
                  <!-- @mouseleave="moreOperate(item)" -->
                  <!-- <span class="span-btn" @mouseenter="moreOperate(item)">
                    <en-icon name="gengduo-liebiao" size="20" style="color:#25C393;margin-left:16px;"></en-icon>
                    <div class="operate-lis" v-show="item.shadow">
                      <div class="cent">
                        <div class="o-mask"></div>
                        <div class="o-lis">
                          <div class="o-btn" v-for="(item2, key) in btnList(item.btnList)" :key="key" @click.stop="btnHandle(item, k, item2)">
                            <span class="o-btn-icon">
                              <en-icon :name="funName(item2)" size="20" :style="funColor(item2)"></en-icon>
                            </span>
                            <span>{{ item2.btnName }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span> -->

                  <el-popover placement="bottom" width="200" trigger="hover" popper-class="myRead__more">
                    <div class="o-lis">
                      <div class="o-btn" v-for="(item2, key) in btnList(item.btnList)" :key="key" @click.stop="btnHandle(item, k, item2)">
                        <span class="o-btn-icon">
                          <en-icon :name="funName(item2)" size="14" :style="funColor(item2)"></en-icon>
                        </span>
                        <span>{{ item2.btnName }}</span>
                      </div>
                    </div>
                    <span class="span-btn" style="margin-left:16px;" slot="reference" title="更多">
                      <en-icon name="gengduo-liebiao" size="20" style="color:#25C393;"></en-icon>
                    </span>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
        <en-result v-if="noData" type="NoData"></en-result>
        <!-- 加载更多 -->
        <!-- <viewAddMore v-if="myNotice.hasNext" :total="myNotice.total" :pageNo="pageNo" :pageSize="pageSize" @addMore="addMore"></viewAddMore> -->
      </div>
    </div>
    <!-- 退回 -->
    <viewReject v-if="noticeType.reject.flg" :show="noticeType.reject.flg" :content="noticeType.reject.content" :type="noticeType.reject.type" @toShow="toget"></viewReject>
    <viewBacktop class="back-top" elementId="waitdo-enlis"></viewBacktop>
    <businessFunction sourceType="toDo" :id="btnObj.id" @flowButtonProcessed="getList" ref="businessFunction" :templateId="btnObj.templateId" :p-id="btnObj.pId"></businessFunction>
  </div>
</template>

<script>
// import { request } from "en-js";
import { throttle } from "lodash";
import { otherEntranceRoute } from "@/components/businessDetail/helper.js";
import { enService } from "@/api/en/index";
import en from "@/mixins/en/en"; // 公共方法
// import businessFunction from "@/components/businessDetail/mixins/buttonFunction/businessFunction.vue";
import CardHeader from "@/views/home/components/card-header";
import viewBacktop from "./readComm/viewBacktop";
import viewSearch from "./readComm/viewSearch";
import viewReject from "./readComm/viewReject";
// import viewAddMore from "./readComm/viewAddMore";
export default {
  name: "AwitDo",
  components: {
    viewBacktop,
    viewSearch,
    CardHeader,
    viewReject,
    businessFunction: () => import("@/components/businessDetail/mixins/buttonFunction/businessFunction.vue")
  },
  mixins: [en],
  props: {
    menuData: {
      type: Object
    }
  },
  directives: {
    scroll: {
      bind(el, binding) {
        let pageNum = 2;
        let hasNext = true;
        el.addEventListener(
          "scroll",
          throttle(async() => {
            // 滚动条距离顶部高度
            const scrollTop = el.scrollTop;
            // 滚动条高度
            const scrollHeight = el.scrollHeight;
            if (scrollHeight - el.clientHeight - scrollTop < 5 && hasNext) {
              // 判断请求发送标志位，避免重复请求(这个需要自己在data里声明，直接贴代码会报错。默认为false，发送请求前改为true， 请求完成回调函数里改回false)
              hasNext = await binding.value(pageNum);
              hasNext ? pageNum++ : "";
            }
          }, 1000)
        );
      },
      // 路由转跳到其他页面时，会调用unbind，解绑滚动加载事件。
      unbind(el, binding, vnode) {
        el.removeEventListener("scroll", vnode.context.scrollLoad);
      }
    }
  },
  data() {
    return {
      myNotice: {},
      noticeList: [], // 展示的 数据
      pageNo: 1, // 页码
      pageSize: 20, // 每页的 数量
      // 是否显示无数据
      noData: false,
      // 标记是否显示 消息的详情标识
      noticeType: {
        reject: { flg: false, content: "", type: "" }, // 退回 驳回type区分
        type048: { flg: false, content: {} },
        remind: { flg: false, content: {} }
      },
      // 查询条件 加载更多时用到
      params: {},
      show: true,
      userId: this.$store.getters.userInfo.userId,
      // 公共弹框的操作 id 数据id pid数据id
      btnObj: {},
      // sortType=000升序、001降序
      sortType: "001",
      // 页面名称
      pageType: "awitDo"
    };
  },
  computed: {
    headerTitle() {
      return "待办事项";
    },
    num() {
      return `${this.myNotice.total && this.myNotice.total > 0 ? `${this.myNotice.total}` : ""}`;
    }
  },
  watch: {},
  mounted() {
    this.handle();
    this.queryUserConfig("taskToDoSortType");
    const This = this;
    this.$refs.enlisbox.addEventListener(
      "scroll",
      throttle(async() => {
        // 滚动条距离顶部高度
        const scrollTop = This.$refs.enlisbox.scrollTop;
        // 滚动条高度
        const scrollHeight = This.$refs.enlisbox.scrollHeight;
        if (scrollHeight - This.$refs.enlisbox.clientHeight - scrollTop < 5 && This.myNotice.hasNext) {
          this.pageNo += 1;
          this.getList(true);
        }
      }, 1000)
    );
  },

  methods: {
    // btnAgree 判断同意是否显示
    btnAgree(item) {
      let flg = false;
      (item.btnList || []).forEach((item) => {
        if (item.btnKey === "agree") {
          flg = true;
        }
      });
      return flg;
    },
    /**
     * 删除数据，有父组件处理
     */
    deleteData() {
      this.$emit("deleteData", this.data);
    },
    editTheme() {
      this.$emit("editTheme");
    },
    // 处理按钮 剔除 seeflow 查看按钮
    btnList(btnList) {
      const list = [];
      btnList.forEach((item) => {
        if (item.btnKey !== "seeflow") {
          list.push(item);
        }
      });
      return list;
    },
    // 返回 操作的按钮名称 用来显示
    funName(item2) {
      let name = "";
      if (item2.btnKey === "agree") {
        // 同意
        name = "tongyianniu-kuaijiecaozuo";
      } else if (item2.btnKey === "submit") {
        // 提交
        name = "tijiao-kuaijiecaozuo";
      } else if (item2.btnKey === "reject") {
        // 退回
        name = "tuihui-kuaijiecaozuo";
      } else if (item2.btnKey === "rebut") {
        // 驳回
        name = "bohui-kuaijiecaozuo";
      } else if (item2.btnKey === "stop") {
        // 终止
        name = "zhongzhi-kuaijiecaozuo";
      } else if (item2.btnKey === "signed") {
        // 加签
        name = "jiaqian-kuaijiecaozuo";
      } else if (item2.btnKey === "propose") {
        // 拟办
        name = "niban-kuaijiecaozuo";
      } else if (item2.btnKey.indexOf("wfcustom") > -1) {
        // 自定义
        name = `bus-btn${item2.icon}`;
      }
      return name;
    },
    funColor(item2) {
      let color = "";
      if (item2.btnKey === "agree") {
        // 同意
        color = "color: #3e90fe;";
      } else if (item2.btnKey === "submit") {
        // 提交
        color = "color: #49bef2;";
      } else if (item2.btnKey === "reject") {
        // 退回
        color = "color: #F76B6B;";
      } else if (item2.btnKey === "rebut") {
        // 驳回
        color = "color: #f7bf28;";
      } else if (item2.btnKey === "stop") {
        // 终止
        color = "color: #f7bf28;";
      } else if (item2.btnKey === "signed") {
        // 加签
        color = "color: #58CB7E;";
      } else if (item2.btnKey === "propose") {
        // 拟办
        color = "color: #49bef2;";
      } else if (item2.btnKey.indexOf("wfcustom") > -1) {
        color = `color: ${item2.color};`;
      }
      return color;
    },
    // 到期提醒显示
    expire(item) {
      let str = "";
      if (Number(item.surplusTime || 0) > 0) {
        const day = parseInt(Number(item.surplusTime || 0) / 60 / 24, 10);
        const hours = parseInt((Number(item.surplusTime || 0) % 1440) / 60, 10);
        const min = parseInt((Number(item.surplusTime || 0) % 1440) % 60, 10);
        // str = day+"天"+hours+"时"+min+"分";
        str = `${(day === 0 ? "" : `${day}天`) + (day === 0 && hours === 0 ? "" : `${hours}时`)}${min}分`;
      } else {
        str = "已超时";
      }
      return str;
    },
    // 查询数据
    async getList(flg) {
      if (flg) {
        // flg为true为加载更多不loading
      } else {
        this.loading = true;
      }

      let p = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        status: "000"
      };
      let rspData;
      const modifyTime = { field: "modifyTime", sortType: this.sortType };
      if (this.pageNo === 1) {
        this.loading = true;
        this.noticeList = [];
      }
      p.sortField = JSON.stringify(modifyTime);
      if (JSON.stringify(this.params) !== "{}") {
        p = Object.assign(p, this.params);
      }
      try {
        rspData = await enService.queryTaskList(p);
      } catch (error) {
        //
        rspData = { records: [] };
      }
      this.myNotice = rspData;
      rspData.records.forEach((element) => {
        this.$set(element, "shadow", false);
      });
      this.noticeList.push(...rspData.records);
      // 判断是否无数据 避免视图闪现无数据
      if (this.noticeList.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
      // console.log(rspData);
      this.loading = false;
      return rspData.hasNext;
    },
    // 点击处理
    async modClick(item, index) {
      console.log("输出点击项", item);
      // 跳转详情
      if (item.readFlag === "000") item.readFlag = "001";
      const pageSwitchData = {
        pageNo: this.pageNo, // 列表请求的当前页
        pageSize: this.pageSize, // size
        selectIndex: index, // 选中的第几条数据index
        interfaceName: "queryTaskList", // 请求的接口名称
        params: {
          pageSize: this.pageSize,
          pageNo: this.pageNo,
          status: "000"
        }, // 该接口所需的参数
        idKey: "businessKey", // 数据id对应的字段
        businessTypeKey: "businessType", // 业务类型对应的字段
        loadedData: this.noticeList // 已加载的数据
      };
      const modifyTime = { field: "modifyTime", sortType: this.sortType };
      pageSwitchData.params.sortField = JSON.stringify(modifyTime);
      this.$router.push(
        otherEntranceRoute({
          id: item.businessKey,
          businessType: item.businessType,
          templateId: item.formId,
          templateName: item.message,
          pageSwitchData
        })
      );
    },
    // 更多
    moreOperate(item) {
      item.shadow = !item.shadow;
    },
    // 弹框返回事件 params 子组件返回值
    toget(params) {
      console.log(params);
      this.noticeType[params.itemName].flg = false;
      if (params.flgRefresh) {
        // 重新获取数据
        this.pageNo = 1;
        this.getList();
      }
    },
    // 获取搜索数据
    getData(data) {
      // 混入方法 "message"
      this.search(data);
    },
    // 同意
    async mdAgree(item, k) {
      if (this.throttle === 2) {
        return;
      }
      this.throttle = 2;
      if (item.businessType === "1" || item.businessType === "2") {
        // 业务建模 基础数据
        const p = {
          pId: item.pId,
          id: item.id,
          approvalComment: ""
        };
        try {
          await enService.mdAgree(p);
          this.noticeList.splice(k, 1);
          this.throttle = 1;
          this.$message({
            message: "同意成功！",
            type: "success"
          });
        } catch (error) {
          this.throttle = 1;
          console.log(error);
        }
      } else {
        // 表单处理 不处理
      }
      this.handle();
      this.pageNo = 1;
      this.getList();
    },
    // 按钮list 处理 item 数据体 k当前index btn按钮信息
    async btnHandle(item, k, btn) {
      console.log(item, k, btn);
      if (btn.btnKey === "agree" || btn.btnKey === "submit") {
        // 同意
        this.mdAgree(item, k);
      } else {
        this.btnObj = {
          id: item.id,
          templateId: item.formId,
          pId: item.pId
        };
        this.$refs.businessFunction.triggerButtonFunction(btn);
      }
    },
    // 批量同意
    async messageBatch() {
      const lis = [];
      this.noticeList.forEach((element) => {
        if (element.nodeId !== "start") {
          const params = {
            tenantId: element.tenantId,
            mainId: element.businessKey,
            userId: element.assignee,
            taskId: element.taskId,
            processDefinitionId: element.processDefinitionId,
            nodeId: element.nodeId,
            pattern: element.pattern || "",
            endorseType: element.endorseType || "",
            executionId: element.executionId,
            createId: element.createId,
            createName: element.createName,
            firstAddSign: element.firstAddSign === undefined ? "" : element.firstAddSign,
            firstExecutionId: element.firstExecutionId === undefined ? "" : element.firstExecutionId,
            formName: element.formName || "",
            barCode: element.barCode,
            billType: element.billType,
            pId: element.pId,
            approvalComment: "同意"
          };
          if (params.pattern === "003" && !params.endorseType) {
            //
          } else {
            lis.push(params);
          }
        }
      });
      if (lis.length === 0) {
        this.$message({
          showClose: true,
          message: "没有可处理的单据！",
          type: "warning"
        });
      } else if (lis.length > 15) {
        this.$message({
          showClose: true,
          message: "批量处理不能超过15条！",
          type: "warning"
        });
      } else {
        this.$confirm("确定要批量处理单据吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.batchApplyAgree(lis);
          })
          .catch();
      }
    },
    async batchApplyAgree(lis) {
      const p = { data: JSON.stringify(lis) };
      const rspData = await enService.batchApplyAgree(p);
      const arr = [];
      if (Number(rspData.successNum) > 0) {
        arr.push(`成功${rspData.successNum}条`);
      }
      if (Number(rspData.failNum) > 0) {
        arr.push(`失败${rspData.failNum}条`);
      }
      if (Number(rspData.noBatchNum) > 0) {
        arr.push(`不能处理${rspData.noBatchNum}条`);
      }
      const str = `批量处理：${arr.join(",")}!`;
      this.$message({
        showClose: true,
        message: str,
        type: "success"
      });
      this.handle();
      this.pageNo = 1;
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
// 一键阅读
.piliangchuli-weidu {
  color: #bebebe;
  line-height: 35px;
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
}
.sotrel {
  margin-right: 10px;
}
// 红点
.msg-remind::before {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  background: #ff6432;
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 0;
  //   right: 10px;
  right: 0;
}

// 最外层
.my-enlis {
  position: relative;
  width: 100%;
  height: 100%;
  .ybox-mod {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    height: 100%;
    .card-define-head {
      padding: 5px 20px;
      // /deep/.right{
      //   min-width: 920px !important;
      // }
    }

    // 标题
    .ybox-title {
      color: #333;
      font-size: 14px;
      line-height: 22px;
      zoom: 1;
      height: auto;
      // border-bottom: 1px solid #ecf1f7;
      overflow: hidden;
      .pull-right {
        float: right !important;
        height: 40px;
        display: flex;
        align-items: center;
        .shu {
          display: inline-block;
          width: 1px;
          height: 24px;
          background: #dbe5eb;
          vertical-align: middle;
          margin: 0 10px;
        }
      }
    }

    // 内容
    .ybox-content {
      padding: 0;
      zoom: 1;
      height: calc(100% - 52px);
      .enlis {
        height: calc(100% - 46px);
        overflow-y: auto;
        font-size: 12px;
        padding-bottom: 0;
        zoom: 1;
        scroll-behavior: smooth; //平滑滚动
        padding-left: 10px;
        padding-right: 10px;
        //单个设置
        .mod:last-child {
          border-bottom: 0;
        }
        .mod-box {
          position: relative;
          min-height: 80px;
          width: 100%;
          .mod {
            padding: 10px 2% 0;
            padding-left: 80px;
            line-height: 24px;
            color: #606060;
            cursor: pointer;
            zoom: 1;
            // min-height: 72px;
            min-height: 80px;
            background: rgba(232, 236, 242, 0.25);
            border-radius: 4px;
            margin-bottom: 8px;
            // border-bottom: 1px solid #f3f3f3;
            .col {
              min-height: 24px;
              p {
                display: inline-block;
                margin: 0;
              }
            }
            // 图标
            .col-icon {
              min-height: 24px;
              position: absolute;
              left: 20px;
              top: 15px;
              width: 40px;

              .iconfont {
                background-color: #3e90fe;
                width: 40px;
                height: 40px;
                color: #fff;
                border-radius: 50%;
                line-height: 40px;
                text-align: center;
                display: block;
                margin: 0;
                font-size: 22px;
                font-style: normal;
              }
            }
            // 左侧
            .pull-left {
              float: left !important;
              text-align: left;
              width: 70%;
              .col-num {
                display: inline-block;
                vertical-align: top;
              }
              .col-tit {
              }
            }
            // 右侧
            .pull-right {
              width: 20%;
              float: right !important;
              text-align: right;
              .col-time {
                color: #91a1b7;
              }
              .span-btn {
                // display: none;
                visibility: hidden;
              }
            }
          }
          .mod:after {
            content: "";
            display: block;
            clear: both;
          }
          .mod:hover {
            // background: #f5f9fd;
            background: rgba(162, 205, 247, 0.25);
            cursor: pointer;
            .span-btn {
              display: inline;
              visibility: initial;
            }
          }
          // 更多操作 阴影
          .operate-lis {
            position: absolute;
            width: auto;
            height: 70px;
            top: 75px;
            right: 15px;
            z-index: 99;
            border-radius: 5px;
            .cent {
              position: relative;
              width: auto;
              height: 100%;
              border-radius: 5px;
              .o-mask {
                position: absolute;
                top: -10px;
                right: 20px;
                border-top: 5px transparent dashed;
                border-left: 5px transparent dashed;
                border-right: 5px transparent dashed;
                border-bottom: 5px rgba(25, 47, 69, 0.5) solid;
              }
              .o-lis {
                background: rgba(25, 47, 69, 0.5);
                display: flex;
                position: relative;
                z-index: 120;
                width: auto;
                height: 100%;
                margin: 0 auto;
                align-items: center;
                justify-content: flex-end;
                color: #ffffff;
                font-size: 12px;
                border-radius: 5px;
                .o-btn {
                  margin: 0 8px;
                  display: flex;
                  align-items: center;
                  .o-btn-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #f5fcff;
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                    margin-right: 6px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.myRead__more {
  padding: 0;
  height: 70px;
  z-index: 120;
  width: auto !important;
  margin: 0 auto;
  border: 0;
  border-radius: 5px;
  background: rgba($color: #dcdfe6, $alpha: 0.8);
  // .popper__arrow {
  //   border-bottom-color: rgba(25, 47, 69, 0.5);
  //   border-top-color: rgba(25, 47, 69, 0.5);
  // }
  .popper__arrow::after {
    border-bottom-color: rgba(25, 47, 69, 0.5) !important;
    border-top-color: rgba(25, 47, 69, 0.5) !important;
  }

  .o-lis {
    background: rgba(25, 47, 69, 0.5);
    display: flex;
    // position: relative;
    z-index: 120;
    width: auto;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: flex-end;
    color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    .o-btn {
      margin: 0 8px;
      display: flex;
      align-items: center;
      .o-btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5fcff;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        margin-right: 6px;
      }
    }
    span {
      cursor: pointer;
    }
  }
}
</style>
