var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-enlis",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.hideSearch.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "ybox-mod enbuss-mod" }, [
        _c(
          "div",
          { staticClass: "ybox-title" },
          [
            _c("card-header", {
              attrs: {
                title: _vm.headerTitle,
                num: _vm.num,
                edit: _vm.$attrs.edit,
                menuData: _vm.menuData,
              },
              on: { deleteData: _vm.deleteData, editTheme: _vm.editTheme },
              scopedSlots: _vm._u([
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "pull-right" },
                        [
                          _vm.type === "itself" && !_vm.$attrs.edit
                            ? _c("viewSearch", {
                                attrs: { show: _vm.show },
                                on: { toData: _vm.getData },
                              })
                            : _vm._e(),
                          _vm.type === "itself" && !_vm.$attrs.edit
                            ? _c("span", { staticClass: "shu" })
                            : _vm._e(),
                          _vm.type === "itself" &&
                          _vm.sortType === "001" &&
                          _vm.sortLoad &&
                          !_vm.$attrs.edit
                            ? _c(
                                "en-image-setting",
                                {
                                  staticClass: "piliangchuli-weidu sotrel",
                                  attrs: {
                                    name: "jiangxu",
                                    size: "small",
                                    fill: "rgb(169, 181, 198)",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.sortClick(
                                        "000",
                                        "taskToDoSortType"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 降序 ")]
                              )
                            : _vm._e(),
                          _vm.type === "itself" &&
                          _vm.sortType === "000" &&
                          _vm.sortLoad &&
                          !_vm.$attrs.edit
                            ? _c(
                                "en-image-setting",
                                {
                                  staticClass: "piliangchuli-weidu sotrel",
                                  attrs: {
                                    name: "shengxu1",
                                    size: "small",
                                    fill: "rgb(169, 181, 198)",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.sortClick(
                                        "001",
                                        "taskToDoSortType"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 升序 ")]
                              )
                            : _vm._e(),
                          _vm.mainBatchOpera && !_vm.$attrs.edit
                            ? _c(
                                "en-image-setting",
                                {
                                  staticClass: "piliangchuli-weidu",
                                  attrs: {
                                    name: "piliangchuli",
                                    size: "small",
                                    fill: "#4593DF",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.messageBatch()
                                    },
                                  },
                                },
                                [_vm._v(" 批量操作 ")]
                              )
                            : _vm._e(),
                          _vm.type === "index" && !_vm.$attrs.edit
                            ? _c("en-icon", {
                                staticStyle: {
                                  color: "#A9B5C6",
                                  cursor: "pointer",
                                  "margin-left": "10px",
                                },
                                attrs: {
                                  name: "zuocecaidanlan-shouqi",
                                  size: "20",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.goDetails("/waitdo")
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "en-loading",
                rawName: "v-en-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "ybox-content",
          },
          [
            !_vm.noData
              ? _c(
                  "div",
                  {
                    ref: "enlisbox",
                    staticClass: "enlis enlis-buss",
                    attrs: { id: "waitdo-enlis" },
                  },
                  _vm._l(_vm.noticeList, function (item, k) {
                    return _c("div", { key: k, staticClass: "mod-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "mod",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.modClick(item, k)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col col-icon",
                              class:
                                item.readFlag === "000" ? "msg-remind" : "",
                            },
                            [
                              _c(
                                "p",
                                [
                                  _c("en-user-logo", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                    },
                                    attrs: {
                                      "user-name": item.createName,
                                      "image-url": item.userLogo,
                                      size: "40",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "pull-left" }, [
                            _c("div", { staticClass: "col col-num" }, [
                              _c(
                                "p",
                                [
                                  _vm._v(
                                    " " + _vm._s(item.createName || "") + " "
                                  ),
                                  item.assignee !== _vm.userId
                                    ? _c("en-icon", {
                                        staticStyle: { color: "#3e90fe" },
                                        attrs: {
                                          name: "dailishenpiren",
                                          size: "16",
                                        },
                                      })
                                    : _vm._e(),
                                  item.approvalLimitTime &&
                                  (item.surplusTime || 0) < 4320
                                    ? _c("en-icon", {
                                        style:
                                          Number(item.surplusTime || 0) > 0
                                            ? "color:#12b7f5;"
                                            : "color:#f54646;",
                                        attrs: { name: "gongshi", size: "16" },
                                      })
                                    : _vm._e(),
                                  item.approvalLimitTime &&
                                  (item.surplusTime || 0) < 4320
                                    ? _c(
                                        "span",
                                        {
                                          style:
                                            Number(item.surplusTime || 0) > 0
                                              ? "color:#12b7f5;"
                                              : "color:#f54646;",
                                        },
                                        [_vm._v(_vm._s(_vm.expire(item)))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col col-tit" }, [
                              _c("p", [_vm._v(_vm._s(item["message"] || ""))]),
                            ]),
                          ]),
                          _c("div", { staticClass: "pull-right" }, [
                            _c("div", { staticClass: "col col-time" }, [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.getDateFormat(item["createTime"]))
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "col col-operate",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "flex-end",
                                },
                              },
                              [
                                _vm.btnAgree(item)
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "span-btn",
                                        attrs: { title: "同意" },
                                      },
                                      [
                                        _c("en-icon", {
                                          staticStyle: { color: "#3e90fe" },
                                          attrs: {
                                            name: "tongyi-kuaijiecaozuo",
                                            size: "20",
                                          },
                                          nativeOn: {
                                            "~click": function ($event) {
                                              $event.stopPropagation()
                                              return _vm.mdAgree(item, k)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      width: "200",
                                      trigger: "hover",
                                      "popper-class": "myRead__more",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "o-lis" },
                                      _vm._l(
                                        _vm.btnList(item.btnList),
                                        function (item2, key) {
                                          return _c(
                                            "div",
                                            {
                                              key: key,
                                              staticClass: "o-btn",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.btnHandle(
                                                    item,
                                                    k,
                                                    item2
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "o-btn-icon" },
                                                [
                                                  _c("en-icon", {
                                                    style: _vm.funColor(item2),
                                                    attrs: {
                                                      name: _vm.funName(item2),
                                                      size: "14",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(item2.btnName)),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "span-btn",
                                        staticStyle: { "margin-left": "16px" },
                                        attrs: {
                                          slot: "reference",
                                          title: "更多",
                                        },
                                        slot: "reference",
                                      },
                                      [
                                        _c("en-icon", {
                                          staticStyle: { color: "#25C393" },
                                          attrs: {
                                            name: "gengduo-liebiao",
                                            size: "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.noData
              ? _c("en-result", { attrs: { type: "NoData" } })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.noticeType.reject.flg
        ? _c("viewReject", {
            attrs: {
              show: _vm.noticeType.reject.flg,
              content: _vm.noticeType.reject.content,
              type: _vm.noticeType.reject.type,
            },
            on: { toShow: _vm.toget },
          })
        : _vm._e(),
      _c("viewBacktop", {
        staticClass: "back-top",
        attrs: { elementId: "waitdo-enlis" },
      }),
      _c("businessFunction", {
        ref: "businessFunction",
        attrs: {
          sourceType: "toDo",
          id: _vm.btnObj.id,
          templateId: _vm.btnObj.templateId,
          "p-id": _vm.btnObj.pId,
        },
        on: { flowButtonProcessed: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }